import React from "react";
import { Col, Container, Row } from "react-grid-system";
import {
  MobileModalContent,
  MobilePageDescription,
  MobilePageHeader,
} from "../../components";
import { useTranslation } from "react-i18next";

const RowColWrapper = ({ children }) => (
  <Row>
    <Col xs={12}>{children}</Col>
  </Row>
);

const P404 = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <MobileModalContent height={"calc(100vh - 56px)"}>
        <RowColWrapper>
          <MobilePageHeader>{t("views.p404.header")}</MobilePageHeader>
        </RowColWrapper>

        <RowColWrapper>
          <MobilePageDescription>{t("views.p404.desc")}</MobilePageDescription>
        </RowColWrapper>
      </MobileModalContent>
    </Container>
  );
};

export default P404;
