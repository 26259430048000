import produce from "immer";

const initialState = {};

const appReducer = (state = initialState, action) => {
    const { type, payload } = action;

    return produce(state, (draft)=>{
        switch (type) {
            default: return draft;
        }
    });
};

export default appReducer;
