import styled from "styled-components";

export const LanguageSelectorWrapper = styled.div`
  position: absolute;
  top: -30px;
  right: 0;
  height: auto;
  display: flex;
  justify-content: end;
`;
