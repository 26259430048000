import React from "react";
import { Col, Container, Row } from "react-grid-system";
import {
  Button,
  MobileModalContent,
  MobilePageDescription,
  MobilePageHeader,
} from "../../components";
import { useTranslation } from "react-i18next";

const RowColWrapper = ({ children }) => (
  <Row>
    <Col xs={12}>{children}</Col>
  </Row>
);

const ModalSaveCard = ({ cardUrl }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <MobileModalContent height={"100%"}>
        <RowColWrapper>
          <MobilePageHeader>
            {t("views.modal_save_card.header")}
          </MobilePageHeader>
        </RowColWrapper>

        <RowColWrapper>
          <MobilePageDescription>
            {t("views.modal_save_card.desc")}
          </MobilePageDescription>
        </RowColWrapper>

        <RowColWrapper>
          <Button type={"primary"} display={"block"} tag={"a"} href={cardUrl}>
            {t("views.modal_save_card.btn_save")}
          </Button>
        </RowColWrapper>
      </MobileModalContent>
    </Container>
  );
};

export default ModalSaveCard;
