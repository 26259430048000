import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-grid-system";
import { LanguageSelectorWrapper } from "../../components/Registration/styled";
import {
  Button,
  Error,
  MobilePageDescription,
  MobilePageHeader,
  PhoneInput,
  LanguageSelector,
} from "../../components";
import { useHistory, useLocation } from "react-router-dom";
import {
  checkLocation,
  checkTemplate,
  register,
} from "../../store/app/actions";
import { useDispatch } from "react-redux";
import { setModalContent, setModalVisible } from "../../store/modal/actions";
import * as Yup from "yup";
import { ModalConfirmCode, ModalError, ModalSaveCard } from "../index";
import { useTranslation } from "react-i18next";
import useForm from "../../hooks/useForm";
import i18n, {
  countryPhoneDictionary,
  IETFDictionary,
  resources,
} from "../../localization/i18n";
import {
  getMaskLengthByLang,
  getPhoneCodeByLang,
  getMaskByCode,
} from "../../utils/phone";

const codeItems = Object.entries(countryPhoneDictionary).map(
  ([key, obj], i) => ({ id: i, tag: key, code: obj.code })
);

const languageItems = Object.keys(resources).map((tag, i) => ({
  id: i + 1,
  label: IETFDictionary[tag],
  tag: tag,
}));

function useRegistrationForm(onSubmit) {
  const { t } = useTranslation();
  const validLengthRef = useRef(getMaskLengthByLang(i18n.language));
  const form = useForm(
    {
      code: getPhoneCodeByLang(i18n.language),
      phone: "",
    },
    Yup.object({
      phone: Yup.string()
        .required(t("core.validation.required"))
        .length(validLengthRef.current, t("core.validation.phone.invalid")),
    }),
    onSubmit
  );
  form.setPhoneValidLength = function (v) {
    validLengthRef.current = v;
  };
  return form;
}

const RowColWrapper = ({ children }) => (
  <Row>
    <Col xs={12}>{children}</Col>
  </Row>
);

const Registration = () => {
  const { t } = useTranslation();
  const location = new URLSearchParams(useLocation().search).get("id");
  const dispatch = useDispatch();

  //Проверка на существование локациии и шаблона карты
  const history = useHistory();
  useEffect(() => {
    const check = async () => {
      const result = await checkLocation(location);
      if (!result) {
        history.push("/404");
      }
    };
    const template = async () => {
      const { result, msg } = await checkTemplate(location);
      if (!result) {
        dispatch(setModalVisible(true));
        dispatch(
          setModalContent(
            <ModalError
              title={t("views.registration.error_title")}
              desc={msg}
            />
          )
        );
      }
    };
    if (location) {
      check();
      template();
    } else {
      history.push("/404");
    }
  }, [location]);

  const [registerError, setRegisterError] = useState("");

  const onSubmit = async (values) => {
    const { code, phone } = values;
    const { result, id, cardUrl } = await register(
      location,
      `${code} ${phone}`.replace(/\D/g, "")
    );
    if (result) {
      setRegisterError("");
      dispatch(setModalVisible(true));
      if (cardUrl) {
        dispatch(setModalContent(<ModalSaveCard cardUrl={cardUrl} />));
      } else {
        dispatch(setModalContent(<ModalConfirmCode userId={id} />));
      }
    } else {
      setRegisterError(t("views.registration.msg_error_try_again"));
    }
  };

  const form = useRegistrationForm(onSubmit);

  useEffect(() => {
    form.setFieldValue(
      "code",
      codeItems.find((el) => el.code === getPhoneCodeByLang(i18n.language))
        .code,
      false
    );
  }, [i18n.language]);

  useEffect(() => {
    form.setFieldError("phone", undefined);
    const newValidLength = Object.values(countryPhoneDictionary).find(
      (el) => el.code === form.values.code
    ).mask.length;
    form.setPhoneValidLength(newValidLength);
    form.setFieldValue("phone", form.values.phone.slice(0, newValidLength));
  }, [form.values.code]);

  return (
    <Container>
      <RowColWrapper>
        <LanguageSelectorWrapper>
          <LanguageSelector items={languageItems} />
        </LanguageSelectorWrapper>
      </RowColWrapper>

      <RowColWrapper>
        <MobilePageHeader>{t("views.registration.header")}</MobilePageHeader>
      </RowColWrapper>

      <RowColWrapper>
        <MobilePageDescription>
          {t("views.registration.desc")}
        </MobilePageDescription>
      </RowColWrapper>

      <form onSubmit={form.handleSubmit}>
        <RowColWrapper>
          <PhoneInput
            name={"phone"}
            code={form.values.code}
            setCode={(v) => form.setFieldValue("code", v, false)}
            value={form.values.phone}
            setValue={(v) => form.setFieldValue("phone", v, true)}
            codeItems={codeItems}
            placeholder={t("core.inputs_defs.phone.label")}
            error={form.isError("phone") ? form.errors.phone : ""}
            maskWithoutCode={getMaskByCode(form.values.code)}
          />
          <Error>{registerError}</Error>
        </RowColWrapper>

        <RowColWrapper>
          <Button display={"block"} disabled={false}>
            {t("views.registration.btn_submit")}
          </Button>
        </RowColWrapper>
      </form>

      <Row>
        <Button
          type={"flat"}
          target={"_blank"}
          tag={"a"}
          href={"https://6tamp.ru/policy"}
        >
          {t("core.policy.pre") + " "}
          <u>{t("core.policy.post")}</u>
        </Button>
      </Row>
    </Container>
  );
};

export default Registration;
