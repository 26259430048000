import apiService from "../../utils/apiService";
import i18n from "../../localization/i18n";

export const checkLocation = async (locationId) => {
  try {
    if (locationId) {
      const { data } = await apiService.get(`/location/${locationId}/check`);
      return data.result;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const register = async (location, phone) => {
  try {
    phone = phone.replace(/\D/g, "");
    const { data } = await apiService.post(
      `/client/register/${location}/${phone}`
    );
    return {
      result: true,
      ...data,
    };
  } catch (e) {
    return { result: false, msg: i18n.t("core.actions_msgs.server_error") };
  }
};

export const sendCode = async (id) => {
  try {
    await apiService.post(`/client/${id}/confirmation/send`);
    return { result: true };
  } catch (e) {
    return { result: false, msg: i18n.t("core.actions_msgs.server_error") };
  }
};

export const checkCode = async (id, code) => {
  try {
    const { data } = await apiService.post(`/client/${id}/confirmation/check`, {
      code,
    });
    return { result: true, cardUrl: data.cardUrl };
  } catch (e) {
    if (e.isAxiosError) {
      switch (e.response?.status) {
        case 400:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.wrong_verify_code"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const checkTemplate = async (locationId) => {
  try {
    await apiService.get(`/card/template/${locationId}`);
    return { result: true };
  } catch (e) {
    if (e.isAxiosError) {
      switch (e.response?.status) {
        case 404:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.need_template"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};
