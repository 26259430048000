import styled from "styled-components";

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(40, 40, 40, 0.5);
`;
