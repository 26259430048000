import React, { useRef, useState, useCallback, useEffect } from "react";

function useClickOutside(effect) {
  const ref = useRef(null);
  const [clickedOutside, setClickedOutside] = useState(false);

  const isClickOutside = useCallback((e) => {
    const path = e.path || (e.composedPath && e.composedPath());
    setClickedOutside(!path.includes(ref.current));
  }, []);

  useEffect(() => {
    document.body.addEventListener("click", isClickOutside);
    return () => {
      document.body.removeEventListener("click", isClickOutside);
    };
  }, []);

  useEffect(() => {
    if (clickedOutside) {
      effect();
    }
  }, [clickedOutside]);

  return ref;
}

export default useClickOutside;
