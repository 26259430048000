import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import storesList from "./storesList";

const store = createStore(
    combineReducers(storesList),
    compose(applyMiddleware(thunk))
);

export default store;
