import styled from "styled-components";
import style from "../style";
import closeIcon from "./CloseIcon.svg";

export const LanguageModalContent = styled.div`
  width: calc(100vw - 20px);
  max-width: 500px;
  height: auto;
  max-height: 50vh;
  overflow: auto;
  box-sizing: border-box;
  padding: 30px 20px;
  background-color: #ffffff;
  border-radius: 20px;
  z-index: 4;
`;

export const ModalInner = styled.div`
  display: grid;
  gap: 20px 0;
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const CloseIcon = styled.div`
  width: 16px;
  height: 16px;

  background-image: url(${closeIcon});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const ModalItemsContainer = styled.div`
  display: grid;
`;

export const ModalItem = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  color: ${({ is_active }) =>
    is_active ? style.buttons.primary_inverse.text : style.mainText};
`;
