import { Container, Row, Col } from "react-grid-system";
import React from "react";
import {
  MobileModalContent,
  MobilePageDescription,
  MobilePageHeader,
} from "../../components";

export default function ModalError({ title, desc }) {
  return (
    <Container>
      <MobileModalContent height={"100%"}>
        <Row>
          <Col xs={12}>
            <MobilePageHeader>{title}</MobilePageHeader>
          </Col>
        </Row>
        {desc && (
          <Row>
            <Col xs={12}>
              <MobilePageDescription>{desc}</MobilePageDescription>
            </Col>
          </Row>
        )}
      </MobileModalContent>
    </Container>
  );
}
