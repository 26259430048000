import styled from "styled-components";
import arrow from "./Arrow.svg";
import style from "../style";

export const Select = styled.div`
  width: 100%;
  height: auto;
  position: relative;

  span {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }
`;

export const SelectButton = styled.div`
  width: 100%;
  height: 44px;
  border-radius: 4px;
  border: 1px solid ${style.input.normal.border};
  padding: 0 10px;
  box-sizing: border-box;
`;

export const SelectButtonInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Arrow = styled.div`
  width: 10px;
  height: 5px;

  background-image: url(${arrow});
  background-size: contain;
  background-repeat: no-repeat;

  transform: ${({ turn_down }) => (turn_down ? "rotate(180deg)" : "")};
  transition: transform 0.15s ease-in;
`;

export const SelectItemsContainer = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  height: auto;
  border-radius: 4px;
  border: 1px solid ${style.buttons.primary_inverse.text};
  top: 46px;
  background-color: #ffffff;
`;

export const SelectItem = styled.div`
  width: 100%;
  height: 33px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ is_active }) =>
    is_active ? style.buttons.primary_inverse.text : style.mainText};
`;
