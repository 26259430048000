import React, { useState } from "react";
import { WebIcon } from "./style";
import { LanguageModal } from "../../containers";

const LanguageSelector = ({ items }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <WebIcon onClick={() => setIsActive((v) => !v)} />
      {isActive && (
        <LanguageModal
          title={"Выберите язык"}
          items={items}
          onClose={() => setIsActive(false)}
        />
      )}
    </>
  );
};

export default LanguageSelector;
