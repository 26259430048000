import styled from "styled-components";
import style from "../../style";

const PageDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${style.secondaryText};
  margin-bottom: 40px;

  text-align: ${({ align }) => align};
`;

PageDescription.defaultProps = {
  align: "center",
};

export default PageDescription;
