import { BrowserRouter, Redirect, Route } from "react-router-dom";
import { P404, Registration } from "./containers";
import { BounceSwitch, Global, Modal, NavBar } from "./components";
import { Container, setConfiguration } from "react-grid-system";
import "./localization/i18n";
import { useEffect } from "react";
import { getUserLocale } from "get-user-locale";
import i18n from "./localization/i18n";

const App = () => {
  setConfiguration({ maxScreenClass: "sm" });

  useEffect(() => {
    i18n.changeLanguage(getUserLocale().split("-")[0]);
  }, []);

  return (
    <>
      <Global />
      <Container>
        <BrowserRouter>
          <Modal />
          <NavBar />
          <BounceSwitch>
            <Route path={"/"} component={Registration} exact />
            <Route path={"/404"} component={P404} exact />
            <Redirect to={"/404"} />
          </BounceSwitch>
        </BrowserRouter>
      </Container>
    </>
  );
};

export default App;
