import React, { useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import {
  Button,
  Error,
  MobileModalContent,
  MobilePageDescription,
  MobilePageHeader,
} from "../../components";
import * as Styled from "../../components/ModalConfirmCode/styled";
import { useInterval } from "../../hooks/useInterval";
import { checkCode, sendCode } from "../../store/app/actions";
import { useDispatch } from "react-redux";
import { setModalContent } from "../../store/modal/actions";
import { ModalSaveCard } from "../index";
import { useTranslation } from "react-i18next";

const RowColWrapper = ({ children }) => (
  <Row>
    <Col xs={12}>{children}</Col>
  </Row>
);

const ModalConfirmCode = ({ userId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [wrongCodeError, setWrongCodeError] = useState("");
  const { available, reset, timerValue } = useInterval(10, () =>
    sendCode(userId)
  );
  const onCodeCompleted = async (code) => {
    if (code.length === 4) {
      const { result, cardUrl, msg } = await checkCode(userId, code);
      if (result) {
        setWrongCodeError("");
        dispatch(setModalContent(<ModalSaveCard cardUrl={cardUrl} />));
      } else {
        setWrongCodeError(msg);
      }
    }
  };

  return (
    <Container>
      <MobileModalContent height={"100%"}>
        <RowColWrapper>
          <MobilePageHeader align={"left"}>
            {t("views.modal_confirm_code.header")}
          </MobilePageHeader>
        </RowColWrapper>

        <RowColWrapper>
          <MobilePageDescription align={"left"}>
            {t("views.modal_confirm_code.desc")}
          </MobilePageDescription>
        </RowColWrapper>

        <Row style={{ justifyContent: "center" }}>
          <Styled.CodeInput
            inputStyle={{
              width: "15%",
              fontFamily: "inherit",
              fontSize: "40px",
              lineHeight: "24px",
              border: "none",
              borderBottom: "1px solid #C4C4C4",
              margin: "10px",
              textAlign: "center",
              fontWeight: 400,
            }}
            type={"number"}
            onChange={onCodeCompleted}
            fields={4}
            inputMode={"numeric"}
            name={"confirm_code"}
          />
          <Error>{wrongCodeError}</Error>
        </Row>

        <RowColWrapper>
          <Button type={"flat"} onClick={reset} disabled={!available}>
            {available
              ? t("views.modal_confirm_code.btn_code_again")
              : `${t(
                  "views.modal_confirm_code.btn_code_again_timer.pre"
                )} ${timerValue} ${t(
                  "views.modal_confirm_code.btn_code_again_timer.post"
                )}`}
          </Button>
        </RowColWrapper>
      </MobileModalContent>
    </Container>
  );
};

export default ModalConfirmCode;
