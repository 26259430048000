import axios from "axios";

const apiService = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_TYPE === "PROD"
        ? "https://v2.api.6tamp.ru"
        : "https://v2.api.lip1.ru"
      : "http://localhost:8080",
});

export default apiService;
