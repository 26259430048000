import React, { useState, useEffect } from "react";
import * as Styled from "./style";
import { CloseIcon } from "./style";
import i18n from "../../localization/i18n";
import { useClickOutside } from "../../hooks";

const LanguageItem = ({ onClick, label, isActive }) => {
  return (
    <Styled.ModalItem is_active={isActive} onClick={onClick}>
      <span>{label}</span>
    </Styled.ModalItem>
  );
};

const LanguageModalContent = ({ title, items, onClose }) => {
  const [selected, setSelected] = useState(
    items.find((el) => el.tag === i18n.language)
  );
  const ref = useClickOutside(onClose);

  useEffect(() => {
    i18n.changeLanguage(selected.tag);
  }, [selected]);

  return (
    <Styled.LanguageModalContent ref={ref}>
      <Styled.ModalInner>
        <Styled.ModalHeader>
          <h2>{title}</h2>
          <CloseIcon onClick={onClose} />
        </Styled.ModalHeader>
        <Styled.ModalItemsContainer>
          {items.map((el) => (
            <LanguageItem
              key={el.id}
              onClick={() => setSelected(el)}
              label={el.label}
              isActive={el.id === selected.id}
            />
          ))}
        </Styled.ModalItemsContainer>
      </Styled.ModalInner>
    </Styled.LanguageModalContent>
  );
};

export default LanguageModalContent;
