import { useFormik } from "formik";
import { isError } from "../utils/formikExtensions";

function useForm(initialValues, validationSchema, onSubmit) {
  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  form.isError = isError;
  return form;
}

export default useForm;
