import { Wrapper } from "./styled";
import { useSelector } from "react-redux";
const Modal = () => {
  const { visible, mode, content } = useSelector(({ modal }) => modal);
  return (
    <Wrapper visible={visible} mode={mode}>
      {content}
    </Wrapper>
  );
};

export default Modal;
