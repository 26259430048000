import React from "react";
import ReactDOM from "react-dom";
import * as Styled from "./style";

const ModalWrapper = ({ children }) => {
  return ReactDOM.createPortal(
    <Styled.ModalWrapper>{children}</Styled.ModalWrapper>,
    document.getElementById("modal-root")
  );
};

export default ModalWrapper;
