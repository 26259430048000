import { useEffect, useState } from "react";

export const useInterval = (timeout, action) => {
  const [available, setAvailable] = useState(false);
  const [timerValue, setTimerValue] = useState(timeout);

  useEffect(() => {
    let timeoutId;
    if (!available) {
      timeoutId = setTimeout(() => {
        setAvailable(true);
      }, timeout * 1000);
    }

    return () => clearTimeout(timeoutId);
  }, [available]);

  useEffect(() => {
    const intId = setInterval(() => setTimerValue(timerValue - 1), 1000);
    return () => clearInterval(intId);
  }, [timerValue]);

  const reset = async () => {
    setTimerValue(timeout);
    setAvailable(false);
    action();
  };

  return { available, reset, timerValue };
};
