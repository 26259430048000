import styled from "styled-components";
import ReactCodeInput from "react-code-input";

export const CodeInput = styled(ReactCodeInput)`
  white-space: nowrap;
  
  input {
    outline: none;
  }

  // disable input arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
