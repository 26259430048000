import React from "react";
import { ModalWrapper, LanguageModalContent } from "../../components";

const LanguageModal = ({ title, items, onClose }) => {
  return (
    <ModalWrapper>
      <LanguageModalContent title={title} items={items} onClose={onClose} />
    </ModalWrapper>
  );
};

export default LanguageModal;
