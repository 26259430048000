import produce from "immer";
import { SET_CONTENT, SET_VISIBLE } from "./types";

const initialState = {
  visible: false,
  content: null,
};

const modalReducer = (state = initialState, action) => {
  const { type, payload } = action;

  return produce(state, (draft) => {
    switch (type) {
      case SET_CONTENT:
        draft.content = payload;
        return draft;
      case SET_VISIBLE:
        draft.visible = payload;
        return draft;
      default:
        return draft;
    }
  });
};

export default modalReducer;
