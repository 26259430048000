import styled from "styled-components";
import webIcon from "./WebIcon.svg";

export const WebIcon = styled.div`
  width: 22px;
  height: 22px;

  background-image: url(${webIcon});
  background-size: contain;
  background-repeat: no-repeat;
`;
